<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
            Copyright  &copy;  {{currentDate | date: 'yyyy'}} <a id="pixinventLink" href="https://www.automatenow.co">Alai Labs Pte Ltd</a>, All rights reserved.          
    </p>
    
        
    </div>
</footer>
<!--Footer Ends-->