import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {Auth} from 'aws-amplify';

@Injectable()
export class AuthService {
  token: string;

  constructor(private router: Router) {
    this.UserData();
  }

  async UserData() {
    try{
      var user = await Auth.currentAuthenticatedUser()
      console.log("user", user)
      if (user) {
        sessionStorage.setItem('user', JSON.stringify(user.signInUserSession));
        JSON.parse(sessionStorage.getItem('user'));
        console.log(JSON.parse(sessionStorage.getItem('user')))
      } else {
        sessionStorage.setItem('user', null);
        JSON.parse(sessionStorage.getItem('user'));
      }    
    }
    catch (e) {
      console.log("Got an error!",e);
      throw e; 
    }    
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {   
    //this.token = null;
    /*sessionStorage.removeItem('user');
    Auth.signOut();
    this.router.navigate(['/pages/login']);*/
  }

  getToken() {    
    return this.token;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    return true;
  }

  get isLoggedIn(): boolean {
    //console.log(sessionStorage.getItem('user'))
    const user = sessionStorage.getItem('user');
    return (user !== null) ? true : false;
    
      
  }

  

}
