import { Injectable } from '@angular/core';
import { resolve } from 'url';
import {Auth} from 'aws-amplify';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders  } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl: string;
  constructor(private router: Router, private _httpClient: HttpClient) { 
    this.baseUrl = environment.restApiURL;
  }

  signUp(params: any) {
    return new Promise((resolve,reject) => {
      Auth.signUp({
        username: params.email,
        password: params.password,
        attributes: {
          email: params.email,
          name: params.name
        }
      }).then((result) => {
        console.log('Signed up successfully: '+JSON.stringify(result));
        sessionStorage.setItem('user', JSON.stringify(result.user));
        resolve(result);
      })
      .catch((err) => {
        console.log('Error while registration: '+JSON.stringify(err));
        reject(err);
      })
    })
  }

  signIn(email: string, password: string): Promise<any> {
    return new Promise((resolve,reject) => {
     Auth.signIn(email,password)
     .then(result => {
       console.log('Result: '+JSON.stringify(result));
       var userSession = result.signInUserSession;
       sessionStorage.setItem('user', userSession);
       console.log('SigninSession: '+JSON.stringify(userSession));
       console.log('Email verified: '+userSession.idToken.payload.email_verified);
       if(userSession!=null && 
         userSession.idToken!=null && 
         userSession.idToken.payload!=null &&
         userSession.idToken.payload.email_verified == true) {
         resolve(userSession);   
       } else {
         reject('Email is not verified. Please check your email and verify');
       }
     })
     .catch(err => {
       console.log(JSON.stringify(err));
       reject(err);
     })
    })
 }

 logout() {   
  //this.token = null;
    sessionStorage.removeItem('user');
    Auth.signOut();
    this.router.navigate(['/pages/login']);
  }

  forgotPassword(params: any) {
    return new Promise((resolve,reject) => {
      Auth.forgotPassword(params.email)
      .then((result) => {
        console.log('Sent forgotPassword mail: '+JSON.stringify(result));
        resolve(result);
      })   
      .catch((err) => {
        console.log('Error while forgotPassword: '+JSON.stringify(err));
        reject(err);
      })  
    })
  }

  resetPassword(params: any) {
    return new Promise((resolve,reject) => {
      Auth.forgotPasswordSubmit(params.name, params.code, params.password)
      .then((result) => {
        console.log('reset mail: '+JSON.stringify(result));
        resolve(result);
      })   
      .catch((err) => {
        console.log('Error while forgotPassword: '+JSON.stringify(err));
        reject(err);
      })  
    })
  }

  getOrgData(data){
    let url = this.baseUrl + `/feedback/org/user/${data}`;
    console.log(url)
      return new Promise((resolve, reject) => {
        this._httpClient.get(url)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      });
  }

}
