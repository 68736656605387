// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //restApiURL: 'http://bded646ab4a2.ngrok.io'
  restApiURL: 'https://services.wegosecure.com',
  //restApiURL: 'https://42f0aabc3cb2.ngrok.io'
  //restApiURL: 'http://d3cab4c134c1.ngrok.io',
  s3Url: 'https://cpg-feedback-photos.s3-ap-southeast-1.amazonaws.com/'
};
